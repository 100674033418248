import { makeAutoObservable } from 'mobx';
import { SymbolTypeEnum, UnrealizedAllocationDto } from '../../../../defs/api';

export class UnrealizedAllocationMobxDto implements UnrealizedAllocationDto {
    symbol: string;

    symbolName: string;

    symbolCurrency: string = '';

    exchangeId: string = '';

    symbolId: string = '';

    type: SymbolTypeEnum = SymbolTypeEnum.Cs;

    invested: number;

    averageInvested: number;

    value: number;

    averageValue: number;

    currentUnitPrice: number;

    currency: string;

    amount: number;

    weight: number;

    color: string;

    profitPercentage: number;

    profitValue: number;

    currencyImpactValue: number;

    currencyImpactPercentage: number;

    totalProfitPercentage: number;

    totalProfitValue: number;

    dividendsValue: number;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: UnrealizedAllocationDto): UnrealizedAllocationMobxDto {
        const mobxDto = new UnrealizedAllocationMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: UnrealizedAllocationDto[]): UnrealizedAllocationMobxDto[] {
        return dtos.map((dto) => UnrealizedAllocationMobxDto.create(dto));
    }
}
