import { makeAutoObservable } from 'mobx';
import { UnrealizedAllocationsOverviewDto } from '../../../../defs/api';

export class UnrealizedAllocationsOverviewMobxDto implements UnrealizedAllocationsOverviewDto {
    invested: number = 0;

    value: number = 0;

    currencyImpactValue: number = 0;

    profit: number = 0;

    currency: string = '';

    dividendsValue: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: UnrealizedAllocationsOverviewDto): UnrealizedAllocationsOverviewMobxDto {
        const mobxDto = new UnrealizedAllocationsOverviewMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: UnrealizedAllocationsOverviewDto[]): UnrealizedAllocationsOverviewMobxDto[] {
        return dtos.map((dto) => UnrealizedAllocationsOverviewMobxDto.create(dto));
    }
}
