import { makeAutoObservable } from 'mobx';
import type { RootStore } from './root-store';

export class TermsAgreementDialogStore {
    rootStore: RootStore;

    isOpened = false;

    confirmLoading = false;

    isCheckboxChecked = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    closeDialog(): void {
        this.isOpened = false;
    }

    openDialog(): void {
        this.isOpened = true;
    }

    switchIsCheckboxChecked(): void {
        this.isCheckboxChecked = !this.isCheckboxChecked;
    }

    startConfirmLoading(): void {
        this.confirmLoading = true;
    }

    stopConfirmLoading(): void {
        this.confirmLoading = false;
    }

    async confirmTerms(): Promise<void> {
        this.startConfirmLoading();

        try {
            await this.rootStore.apiClient.userController.userControllerAgreeTerms();
            await this.rootStore.authStore.fetchUserProfile();
            this.closeDialog();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopConfirmLoading();
        }
    }
}
