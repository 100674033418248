import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
import { PortfolioMobxDto } from '../mobx/dtos/portfolio/portfolio-mobx-dto';
import type { PortfolioStore } from './portfolio-store';
import { ShareTypeEnum } from '../../defs/api';

export class SharePortfolioDialogStore {
    rootStore: RootStore;

    portfolioStore: PortfolioStore;

    portfolio: PortfolioMobxDto | null = null;

    opened = false;

    isShared = false;

    isCopied = false;

    loading = false;

    isShareSlugTaken = false;

    shareSlug = '';

    portfolioLink = '';

    constructor(portfolioStore: PortfolioStore) {
        this.rootStore = portfolioStore.rootStore;
        this.portfolioStore = portfolioStore;

        makeAutoObservable(this, {
            rootStore: false,
            portfolioStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    open(portfolio: PortfolioMobxDto): void {
        this.portfolio = portfolio;
        this.opened = true;
        this.rootStore.alertStore.setModalPlacement();
        this.fetchShareInformation();
    }

    close(): void {
        this.opened = false;
        this.rootStore.alertStore.setDefaultPlacement();
    }

    setIsCopied(isCopied: boolean): void {
        this.isCopied = isCopied;
    }

    setIsShared(isShared: boolean): void {
        this.isShared = isShared;
    }

    setInputShareSlug(value: string): void {
        this.shareSlug = value;
        this.portfolioLink = this.constructPortfolioLink(value);
        if (this.isShareSlugTaken) {
            this.isShareSlugTaken = false;
        }
    }

    constructPortfolioLink(slug: string) {
        return `${process.env.NEXT_PUBLIC_WEB_URL}/portfolio/${slug}`;
    }

    async fetchShareInformation(): Promise<void> {
        this.startLoading();

        try {
            if (!this.portfolio) {
                throw new Error('Portfolio is not selected');
            }

            const shareInfo = await this.rootStore.apiClient.portfolioController.portfolioControllerGetShareInformation(
                { portfolioId: this.portfolio.id },
            );

            runInAction(() => {
                this.shareSlug = shareInfo.shareSlug;
                this.portfolioLink = this.constructPortfolioLink(shareInfo.shareSlug);
                this.isShared = shareInfo.shareType === ShareTypeEnum.All;
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async updateShareInformation(): Promise<void> {
        if (!this.portfolio) {
            throw new Error('Portfolio is not selected');
        }

        this.startLoading();
        try {
            const res = await this.rootStore.apiClient.portfolioController.portfolioControllerUpdateShareInformation({
                portfolioId: this.portfolio.id,
                portfolioShareInformationDto: {
                    shareSlug: this.shareSlug,
                    shareType: this.isShared ? ShareTypeEnum.All : ShareTypeEnum.Disabled,
                },
            });
            if (res) {
                await this.fetchShareInformation();
                this.close();
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            runInAction(() => {
                this.isShareSlugTaken = true;
            });
        } finally {
            this.stopLoading();
        }
    }
}
