import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
import type { WatchlistStore } from './watchlist-store';
import { WatchlistMobxDto } from '../mobx/dtos/watchlist/watchlist-mobx-dto';
import { UpdateWatchlistMobxDto } from '../mobx/dtos/watchlist/update-watchlist-mobx-dto';
import { CreateWatchlistMobxDto } from '../mobx/dtos/watchlist/create-watchlist-mobx-dto';

export class WatchlistDialogStore {
    rootStore: RootStore;

    watchlistStore: WatchlistStore;

    watchlist: WatchlistMobxDto | null = null;

    createDialogOpened = false;

    addSymbolsDialogOpened = false;

    updateDialogOpened = false;

    deleteDialogOpened = false;

    updateDeleteDialogOpened = false;

    loading = false;

    constructor(watchlistStore: WatchlistStore) {
        this.rootStore = watchlistStore.rootStore;
        this.watchlistStore = watchlistStore;

        makeAutoObservable(this, {
            rootStore: false,
            watchlistStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async update(data: UpdateWatchlistMobxDto): Promise<void> {
        try {
            this.startLoading();
            await this.rootStore.apiClient.watchlistsController.watchlistControllerUpdate({
                id: data.id as string,
                updateWatchlistDto: data,
            });
            await this.watchlistStore.fetchWatchlists();
            if (data.id) {
                this.watchlistStore.selectWatchlist(data.id);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }

        this.closeUpdateDialog();
    }

    async create(watchlist: CreateWatchlistMobxDto, symbolId?: string | null): Promise<void> {
        try {
            this.startLoading();
            const newWatchlist = await this.rootStore.apiClient.watchlistsController.watchlistControllerCreate({
                createWatchlistDto: watchlist,
            });
            runInAction(async () => {
                await this.watchlistStore.fetchWatchlists();
                await this.watchlistStore.selectWatchlist(newWatchlist.id);
                if (symbolId) {
                    await this.watchlistStore.addSymbol(symbolId);
                }
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }

        this.closeCreateDialog();
    }

    async deleteWatchlist(): Promise<void> {
        try {
            this.startLoading();
            if (this.watchlistStore.selectedWatchlist) {
                const res = await this.rootStore.apiClient.watchlistsController.watchlistControllerDelete({
                    id: this.watchlistStore.selectedWatchlist.id,
                });
                if (!res) {
                    throw new Error();
                }
            }
            runInAction(async () => {
                await this.watchlistStore.fetchWatchlists();
                this.watchlistStore.selectDefaultWatchlist();
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        }

        runInAction(() => {
            this.watchlistStore.fetchWatchlists();
            this.deleteDialogOpened = false;
            this.updateDialogOpened = false;
        });
        this.stopLoading();
    }

    async deleteSymbol(symbolId: string | null): Promise<void> {
        try {
            this.startLoading();
            if (this.watchlistStore.selectedWatchlist && symbolId) {
                const res = await this.rootStore.apiClient.watchlistsController.watchlistControllerDeleteSymbol({
                    id: this.watchlistStore.selectedWatchlist.id,
                    symbolId,
                });

                if (!res) {
                    throw new Error();
                }
            }

            runInAction(() => {
                this.watchlistStore.fetchSymbols();
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        }

        runInAction(() => {
            this.watchlistStore.fetchWatchlists();
            this.deleteDialogOpened = false;
            this.updateDialogOpened = false;
        });
        this.stopLoading();
    }

    setDeleteDialogOpened(opened: boolean): void {
        this.deleteDialogOpened = opened;
    }

    setUpdateDeleteDialogOpened(opened: boolean): void {
        this.updateDeleteDialogOpened = opened;
    }

    openCreateDialog(): void {
        this.watchlist = new WatchlistMobxDto();
        this.createDialogOpened = true;
        this.rootStore.alertStore.setModalPlacement();
    }

    openUpdateDialog(): void {
        this.watchlist = this.watchlistStore.selectedWatchlist;
        this.updateDialogOpened = true;
        this.rootStore.alertStore.setModalPlacement();
    }

    closeUpdateDialog(): void {
        this.updateDialogOpened = false;
        this.rootStore.alertStore.setDefaultPlacement();
    }

    openAddSymbolsDialog(): void {
        this.watchlist = this.watchlistStore.selectedWatchlist;
        this.addSymbolsDialogOpened = true;
        this.rootStore.alertStore.setModalPlacement();
    }

    closeAddSymbolsDialog(): void {
        this.rootStore.symbolsStore.clearSearch();
        this.addSymbolsDialogOpened = false;
        this.rootStore.alertStore.setDefaultPlacement();
    }

    closeCreateDialog(): void {
        this.createDialogOpened = false;
        this.rootStore.alertStore.setDefaultPlacement();
    }
}
