import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
import type { PortfolioStore } from './portfolio-store';
import { PortfolioMobxDto } from '../mobx/dtos/portfolio/portfolio-mobx-dto';
import { UpdatePortfolioMobxDto } from '../mobx/dtos/portfolio/update-portfolio-mobx-dto';
import { CreatePortfolioMobxDto } from '../mobx/dtos/portfolio/create-portfolio-mobx-dto';

export class PortfolioDialogStore {
    rootStore: RootStore;

    portfolioStore: PortfolioStore;

    portfolio: PortfolioMobxDto | null = null;

    createDialogOpened = false;

    updateDialogOpened = false;

    deleteDialogOpened = false;

    isDeleting = false;

    loading = false;

    constructor(portfolioStore: PortfolioStore) {
        this.rootStore = portfolioStore.rootStore;
        this.portfolioStore = portfolioStore;

        makeAutoObservable(this, {
            rootStore: false,
            portfolioStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async update(data: UpdatePortfolioMobxDto): Promise<void> {
        this.rootStore.portfolioStore.selectedPortfolio.setIsRecalculating(true);

        try {
            this.startLoading();
            await this.rootStore.apiClient.portfolioController.portfolioControllerUpdate({
                id: data.id,
                updatePortfolioDto: data,
            });
            await this.portfolioStore.fetchPortfolioList();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }

        this.closeUpdateDialog();
    }

    async create(data: CreatePortfolioMobxDto): Promise<void> {
        try {
            this.startLoading();
            await this.rootStore.apiClient.portfolioController.portfolioControllerCreate({
                createPortfolioDto: data,
            });
            await this.portfolioStore.fetchPortfolioList();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }

        this.closeCreateDialog();
    }

    async deletePortfolio(): Promise<void> {
        this.isDeleting = true;
        try {
            if (!this.portfolio) {
                return;
            }

            await this.rootStore.apiClient.portfolioController.portfolioControllerDelete({
                id: this.portfolio.id,
            });
            await this.portfolioStore.fetchPortfolioList();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            runInAction(() => {
                this.updateDialogOpened = false;
                this.deleteDialogOpened = false;
                this.isDeleting = false;
            });
            this.stopLoading();
        }
    }

    setDeleteDialogOpened(opened: boolean): void {
        this.deleteDialogOpened = opened;
    }

    openCreateDialog(): void {
        this.rootStore.alertStore.setModalPlacement();
        if (this.rootStore.authStore.userHasFreeTariff && this.portfolioStore.hasPortfolio) {
            this.rootStore.premiumDialogStore.open();
        } else {
            this.portfolio = new PortfolioMobxDto();
            this.createDialogOpened = true;
        }
    }

    openUpdateDialog(portfolio: PortfolioMobxDto): void {
        this.portfolio = portfolio;
        this.updateDialogOpened = true;
        this.rootStore.alertStore.setModalPlacement();
    }

    closeUpdateDialog(): void {
        this.updateDialogOpened = false;
        this.rootStore.alertStore.setDefaultPlacement();
    }

    closeCreateDialog(): void {
        this.createDialogOpened = false;
        this.rootStore.alertStore.setDefaultPlacement();
    }
}
