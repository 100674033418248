import { makeAutoObservable } from 'mobx';
import type { RootStore } from './root-store';
import type { AuthStore } from './auth-store';
import { AlertStore } from './alert-store';

export class ImpersonationDialogStore {
    rootStore: RootStore;

    authStore: AuthStore;

    alertStore: AlertStore;

    activeModal: 'impersonate' | null = null;

    loading = false;

    constructor(authStore: AuthStore) {
        this.rootStore = authStore.rootStore;
        this.authStore = authStore;

        makeAutoObservable(this, {
            rootStore: false,
            authStore: false,
        });
    }

    closeDialog(): void {
        this.activeModal = null;
        this.rootStore.alertStore.setDefaultPlacement();
        this.rootStore.alertStore.clearAllMessages();
    }

    setImpersonateActive(keepMessages = false): void {
        this.activeModal = 'impersonate';
        this.rootStore.alertStore.setModalPlacement();
        if (!keepMessages) {
            this.rootStore.alertStore.clearAllMessages();
        }
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }
}
