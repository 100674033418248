import { makeAutoObservable } from 'mobx';
import { AllocationDto, SymbolTypeEnum } from '../../../../defs/api';

export class AllocationMobxDto implements AllocationDto {
    symbol: string;

    symbolName: string;

    symbolCurrency: string = '';

    exchangeId: string = '';

    symbolId: string = '';

    type: SymbolTypeEnum = SymbolTypeEnum.Cs;

    invested: number;

    averageInvested: number;

    value: number;

    averageValue: number;

    currency: string;

    amount: number;

    weight: number;

    color: string;

    profitPercentage: number;

    profitValue: number;

    currencyImpactValue: number;

    currencyImpactPercentage: number;

    totalProfitPercentage: number;

    totalProfitValue: number;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: AllocationDto): AllocationMobxDto {
        const mobxDto = new AllocationMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: AllocationDto[]): AllocationMobxDto[] {
        return dtos.map((dto) => AllocationMobxDto.create(dto));
    }
}
