/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaginatedWatchlistSymbolDto,
    PaginatedWatchlistSymbolDtoFromJSON,
    PaginatedWatchlistSymbolDtoToJSON,
    SymbolCloseChartDto,
    SymbolCloseChartDtoFromJSON,
    SymbolCloseChartDtoToJSON,
    SymbolDto,
    SymbolDtoFromJSON,
    SymbolDtoToJSON,
    SymbolFundamentalDataDto,
    SymbolFundamentalDataDtoFromJSON,
    SymbolFundamentalDataDtoToJSON,
    SymbolHistoryCloseDto,
    SymbolHistoryCloseDtoFromJSON,
    SymbolHistoryCloseDtoToJSON,
    WatchlistSymbolListSortDto,
    WatchlistSymbolListSortDtoFromJSON,
    WatchlistSymbolListSortDtoToJSON,
} from '../models';

export interface SymbolControllerGetFromWatchlistRequest {
    limit: number;
    offset: number;
    watchlistId: string;
    phrase?: string;
    sort?: WatchlistSymbolListSortDto;
}

export interface SymbolControllerGetFundamentalIdRequest {
    exchangeId: string;
    symbol: string;
}

export interface SymbolControllerGetListRequest {
    phrase: string;
    limit: number;
    watchlistId?: string;
}

export interface SymbolControllerGetSymbolHistoryByIdRequest {
    exchangeId: string;
    symbol: string;
    dateFrom?: string;
}

export interface SymbolControllerGetSymbolHistoryByIdAndDateRequest {
    symbolId: string;
    date: string;
}

/**
 * 
 */
export class SymbolsApi extends runtime.BaseAPI {

    /**
     * Get symbols from watchlist
     */
    async symbolControllerGetFromWatchlistRaw(requestParameters: SymbolControllerGetFromWatchlistRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedWatchlistSymbolDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling symbolControllerGetFromWatchlist.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling symbolControllerGetFromWatchlist.');
        }

        if (requestParameters.watchlistId === null || requestParameters.watchlistId === undefined) {
            throw new runtime.RequiredError('watchlistId','Required parameter requestParameters.watchlistId was null or undefined when calling symbolControllerGetFromWatchlist.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.phrase !== undefined) {
            queryParameters['phrase'] = requestParameters.phrase;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbols/watchlist/{watchlistId}`.replace(`{${"watchlistId"}}`, encodeURIComponent(String(requestParameters.watchlistId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedWatchlistSymbolDtoFromJSON(jsonValue));
    }

    /**
     * Get symbols from watchlist
     */
    async symbolControllerGetFromWatchlist(requestParameters: SymbolControllerGetFromWatchlistRequest, initOverrides?: RequestInit): Promise<PaginatedWatchlistSymbolDto> {
        const response = await this.symbolControllerGetFromWatchlistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get symbol fundamental data by symbolId
     */
    async symbolControllerGetFundamentalIdRaw(requestParameters: SymbolControllerGetFundamentalIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SymbolFundamentalDataDto>> {
        if (requestParameters.exchangeId === null || requestParameters.exchangeId === undefined) {
            throw new runtime.RequiredError('exchangeId','Required parameter requestParameters.exchangeId was null or undefined when calling symbolControllerGetFundamentalId.');
        }

        if (requestParameters.symbol === null || requestParameters.symbol === undefined) {
            throw new runtime.RequiredError('symbol','Required parameter requestParameters.symbol was null or undefined when calling symbolControllerGetFundamentalId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbols/{exchangeId}/{symbol}/fundamental-data`.replace(`{${"exchangeId"}}`, encodeURIComponent(String(requestParameters.exchangeId))).replace(`{${"symbol"}}`, encodeURIComponent(String(requestParameters.symbol))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SymbolFundamentalDataDtoFromJSON(jsonValue));
    }

    /**
     * Get symbol fundamental data by symbolId
     */
    async symbolControllerGetFundamentalId(requestParameters: SymbolControllerGetFundamentalIdRequest, initOverrides?: RequestInit): Promise<SymbolFundamentalDataDto> {
        const response = await this.symbolControllerGetFundamentalIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get symbols by phrase
     */
    async symbolControllerGetListRaw(requestParameters: SymbolControllerGetListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SymbolDto>>> {
        if (requestParameters.phrase === null || requestParameters.phrase === undefined) {
            throw new runtime.RequiredError('phrase','Required parameter requestParameters.phrase was null or undefined when calling symbolControllerGetList.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling symbolControllerGetList.');
        }

        const queryParameters: any = {};

        if (requestParameters.phrase !== undefined) {
            queryParameters['phrase'] = requestParameters.phrase;
        }

        if (requestParameters.watchlistId !== undefined) {
            queryParameters['watchlistId'] = requestParameters.watchlistId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbols`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SymbolDtoFromJSON));
    }

    /**
     * Get symbols by phrase
     */
    async symbolControllerGetList(requestParameters: SymbolControllerGetListRequest, initOverrides?: RequestInit): Promise<Array<SymbolDto>> {
        const response = await this.symbolControllerGetListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get symbol value history by symbolId
     */
    async symbolControllerGetSymbolHistoryByIdRaw(requestParameters: SymbolControllerGetSymbolHistoryByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SymbolCloseChartDto>> {
        if (requestParameters.exchangeId === null || requestParameters.exchangeId === undefined) {
            throw new runtime.RequiredError('exchangeId','Required parameter requestParameters.exchangeId was null or undefined when calling symbolControllerGetSymbolHistoryById.');
        }

        if (requestParameters.symbol === null || requestParameters.symbol === undefined) {
            throw new runtime.RequiredError('symbol','Required parameter requestParameters.symbol was null or undefined when calling symbolControllerGetSymbolHistoryById.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbols/{exchangeId}/{symbol}/fundamental-data/value-history`.replace(`{${"exchangeId"}}`, encodeURIComponent(String(requestParameters.exchangeId))).replace(`{${"symbol"}}`, encodeURIComponent(String(requestParameters.symbol))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SymbolCloseChartDtoFromJSON(jsonValue));
    }

    /**
     * Get symbol value history by symbolId
     */
    async symbolControllerGetSymbolHistoryById(requestParameters: SymbolControllerGetSymbolHistoryByIdRequest, initOverrides?: RequestInit): Promise<SymbolCloseChartDto> {
        const response = await this.symbolControllerGetSymbolHistoryByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get symbol history by symbolId and date
     */
    async symbolControllerGetSymbolHistoryByIdAndDateRaw(requestParameters: SymbolControllerGetSymbolHistoryByIdAndDateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SymbolHistoryCloseDto>> {
        if (requestParameters.symbolId === null || requestParameters.symbolId === undefined) {
            throw new runtime.RequiredError('symbolId','Required parameter requestParameters.symbolId was null or undefined when calling symbolControllerGetSymbolHistoryByIdAndDate.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling symbolControllerGetSymbolHistoryByIdAndDate.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbols/{symbolId}/symbol-history-by-date`.replace(`{${"symbolId"}}`, encodeURIComponent(String(requestParameters.symbolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SymbolHistoryCloseDtoFromJSON(jsonValue));
    }

    /**
     * Get symbol history by symbolId and date
     */
    async symbolControllerGetSymbolHistoryByIdAndDate(requestParameters: SymbolControllerGetSymbolHistoryByIdAndDateRequest, initOverrides?: RequestInit): Promise<SymbolHistoryCloseDto> {
        const response = await this.symbolControllerGetSymbolHistoryByIdAndDateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
