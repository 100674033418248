/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolTypeEnum,
    SymbolTypeEnumFromJSON,
    SymbolTypeEnumFromJSONTyped,
    SymbolTypeEnumToJSON,
} from './SymbolTypeEnum';

/**
 * 
 * @export
 * @interface RealizedAllocationDto
 */
export interface RealizedAllocationDto {
    /**
     * 
     * @type {SymbolTypeEnum}
     * @memberof RealizedAllocationDto
     */
    type: SymbolTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    averageSellUnitPrice: number;
    /**
     * 
     * @type {string}
     * @memberof RealizedAllocationDto
     */
    symbol: string;
    /**
     * 
     * @type {string}
     * @memberof RealizedAllocationDto
     */
    symbolId: string;
    /**
     * 
     * @type {string}
     * @memberof RealizedAllocationDto
     */
    symbolName: string;
    /**
     * 
     * @type {string}
     * @memberof RealizedAllocationDto
     */
    symbolCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof RealizedAllocationDto
     */
    exchangeId: string;
    /**
     * Investovaná částka
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    invested: number;
    /**
     * Průměrná investovaná částka
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    averageInvested: number;
    /**
     * Aktuální hodnota
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    value: number;
    /**
     * Průměrná aktuální hodnota
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    averageValue: number;
    /**
     * Měna
     * @type {string}
     * @memberof RealizedAllocationDto
     */
    currency: string;
    /**
     * Držené množství
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    amount: number;
    /**
     * Držený poměr
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    weight: number;
    /**
     * Barva
     * @type {string}
     * @memberof RealizedAllocationDto
     */
    color: string;
    /**
     * Procentuální zisk
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    profitPercentage: number;
    /**
     * Zisk
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    profitValue: number;
    /**
     * Procentuální zisk (realizovaný + nerealizovaný)
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    totalProfitPercentage: number;
    /**
     * Zisk (realizovaný + nerealizovaný)
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    totalProfitValue: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    currencyImpactValue: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedAllocationDto
     */
    currencyImpactPercentage: number;
}

export function RealizedAllocationDtoFromJSON(json: any): RealizedAllocationDto {
    return RealizedAllocationDtoFromJSONTyped(json, false);
}

export function RealizedAllocationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RealizedAllocationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': SymbolTypeEnumFromJSON(json['type']),
        'averageSellUnitPrice': json['averageSellUnitPrice'],
        'symbol': json['symbol'],
        'symbolId': json['symbolId'],
        'symbolName': json['symbolName'],
        'symbolCurrency': json['symbolCurrency'],
        'exchangeId': json['exchangeId'],
        'invested': json['invested'],
        'averageInvested': json['averageInvested'],
        'value': json['value'],
        'averageValue': json['averageValue'],
        'currency': json['currency'],
        'amount': json['amount'],
        'weight': json['weight'],
        'color': json['color'],
        'profitPercentage': json['profitPercentage'],
        'profitValue': json['profitValue'],
        'totalProfitPercentage': json['totalProfitPercentage'],
        'totalProfitValue': json['totalProfitValue'],
        'currencyImpactValue': json['currencyImpactValue'],
        'currencyImpactPercentage': json['currencyImpactPercentage'],
    };
}

export function RealizedAllocationDtoToJSON(value?: RealizedAllocationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': SymbolTypeEnumToJSON(value.type),
        'averageSellUnitPrice': value.averageSellUnitPrice,
        'symbol': value.symbol,
        'symbolId': value.symbolId,
        'symbolName': value.symbolName,
        'symbolCurrency': value.symbolCurrency,
        'exchangeId': value.exchangeId,
        'invested': value.invested,
        'averageInvested': value.averageInvested,
        'value': value.value,
        'averageValue': value.averageValue,
        'currency': value.currency,
        'amount': value.amount,
        'weight': value.weight,
        'color': value.color,
        'profitPercentage': value.profitPercentage,
        'profitValue': value.profitValue,
        'totalProfitPercentage': value.totalProfitPercentage,
        'totalProfitValue': value.totalProfitValue,
        'currencyImpactValue': value.currencyImpactValue,
        'currencyImpactPercentage': value.currencyImpactPercentage,
    };
}

