import { makeAutoObservable } from 'mobx';
import { RealizedAllocationDto, SymbolTypeEnum } from '../../../../defs/api';

export class RealizedAllocationMobxDto implements RealizedAllocationDto {
    averageSellUnitPrice: number;

    symbol: string;

    symbolName: string;

    symbolCurrency: string = '';

    exchangeId: string = '';

    symbolId: string = '';

    type: SymbolTypeEnum = SymbolTypeEnum.Cs;

    invested: number;

    averageInvested: number;

    value: number;

    averageValue: number;

    currency: string;

    amount: number;

    weight: number;

    color: string;

    profitPercentage: number;

    profitValue: number;

    totalProfitPercentage: number;

    totalProfitValue: number;

    currencyImpactValue: number;

    currencyImpactPercentage: number;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: RealizedAllocationDto): RealizedAllocationMobxDto {
        const mobxDto = new RealizedAllocationMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: RealizedAllocationDto[]): RealizedAllocationMobxDto[] {
        return dtos.map((dto) => RealizedAllocationMobxDto.create(dto));
    }
}
