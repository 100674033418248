import { makeAutoObservable, runInAction } from 'mobx';
import * as uuid from 'uuid';
import { startOfDay } from 'date-fns';
import type { TradeStore } from './trade-store';
import type { RootStore } from './root-store';
import { SymbolCurrencyNotFoundError } from '../../errors/symbol-currency-not-found-error';
import { SymbolMobxDto } from '../mobx/dtos/symbol/symbol-mobx-dto';
import { CreateTradeMobxDto } from '../mobx/dtos/trade/create-trade-mobx-dto';
import { TradeListMobxDto } from '../mobx/dtos/trade/trade-list-mobx-dto';
import { UpdateTradeMobxDto } from '../mobx/dtos/trade/update-trade-mobx-dto';

export class TradeDialogStore {
    rootStore: RootStore;

    tradeStore: TradeStore;

    trade: TradeListMobxDto | null = null;

    createDialogOpened = false;

    updateDialogOpened = false;

    deleteDialogOpened = false;

    symbolSearchPhrase = '';

    symbols: SymbolMobxDto[] = [];

    currentSymbol: SymbolMobxDto | null = null;

    loading = false;

    loadingSearch = false;

    createOrUpdateErrorMessage: string | null = null;

    private delayTimer: NodeJS.Timeout;

    private abortController: AbortController | null = null;

    constructor(tradeStore: TradeStore) {
        this.rootStore = tradeStore.rootStore;
        this.tradeStore = tradeStore;

        makeAutoObservable(this, {
            rootStore: false,
            tradeStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async update(trade: UpdateTradeMobxDto): Promise<void> {
        this.createOrUpdateErrorMessage = null;
        const { selectedPortfolio } = this.rootStore.portfolioStore;

        const tradeToUpdate: UpdateTradeMobxDto = {
            ...trade,
            portfolioId: selectedPortfolio.id,
            date: startOfDay(trade.date),
        };

        try {
            this.startLoading();

            if (!tradeToUpdate.id) {
                throw new Error('Trade id not provided');
            }

            const response = await this.rootStore.apiClient.tradeController.tradeControllerUpdateTrade({
                id: tradeToUpdate.id,
                tradeDto: tradeToUpdate,
            });

            if (!response) {
                throw new Error('No response from the server');
            }

            if (
                response &&
                response?.symbol &&
                response?.currenciesForSymbol &&
                response?.currenciesForSymbol.length > 0
            ) {
                throw new SymbolCurrencyNotFoundError(response.symbol, response.currenciesForSymbol);
            } else {
                await this.rootStore.tradeStore.fetchTradeList();
                this.closeUpdateDialog();
                if (this.rootStore.portfolioStore.selectedPortfolio.processed) {
                    await this.rootStore.portfolioStore.fetchPortfolioList();
                }
            }
        } catch (e) {
            this.createOrUpdateErrorMessage = e.message;
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    async create(trade: CreateTradeMobxDto): Promise<void> {
        this.createOrUpdateErrorMessage = null;
        const { selectedPortfolio } = this.rootStore.portfolioStore;

        const tradeToCreate: CreateTradeMobxDto = {
            ...trade,
            id: uuid.v4(),
            portfolioId: selectedPortfolio.id,
            date: startOfDay(trade.date),
        };

        try {
            this.startLoading();

            const response = await this.rootStore.apiClient.tradeController.tradeControllerCreateTrade({
                createTradeDto: tradeToCreate,
            });

            if (
                !response?.trade &&
                response?.symbol &&
                response?.currenciesForSymbol &&
                response?.currenciesForSymbol.length > 0
            ) {
                throw new SymbolCurrencyNotFoundError(response.symbol, response.currenciesForSymbol);
            }
            await this.tradeStore.fetchTradeList();
            this.closeCreateDialog();
            if (this.rootStore.portfolioStore.selectedPortfolio.processed) {
                await this.rootStore.portfolioStore.fetchPortfolioList();
            }
        } catch (e) {
            this.createOrUpdateErrorMessage = e.message;
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    async deleteTrade(): Promise<void> {
        try {
            this.startLoading();
            if (this.trade?.id) {
                const res = await this.rootStore.apiClient.tradeController.tradeControllerDeleteTrade({
                    id: this.trade?.id,
                });
                if (!res) {
                    throw new Error();
                }
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        }

        if (this.tradeStore.trades.length === 1 && this.tradeStore.page > 1) {
            await this.tradeStore.setPage(this.tradeStore.page - 1);
        } else {
            await this.tradeStore.fetchTradeList();
        }

        runInAction(() => {
            this.rootStore.portfolioStore.fetchPortfolioList();
            this.deleteDialogOpened = false;
            this.updateDialogOpened = false;
        });
        this.stopLoading();
    }

    setDeleteDialogOpened(opened: boolean): void {
        this.deleteDialogOpened = opened;
    }

    openCreateDialog(trade?: TradeListMobxDto): void {
        if (trade?.symbol) {
            this.currentSymbol = SymbolMobxDto.createFromSymbolWithExchangeDto(trade.symbol);
        }
        this.trade = new TradeListMobxDto(trade);
        this.createDialogOpened = true;
        this.rootStore.alertStore.setModalPlacement();
    }

    openUpdateDialog(trade: TradeListMobxDto): void {
        this.currentSymbol = SymbolMobxDto.createFromSymbolWithExchangeDto(trade.symbol);
        this.trade = trade;
        this.updateDialogOpened = true;
        this.rootStore.alertStore.setModalPlacement();
    }

    closeUpdateDialog(): void {
        this.clearSymbolData();
        this.updateDialogOpened = false;
        this.rootStore.alertStore.setDefaultPlacement();
    }

    closeCreateDialog(): void {
        this.clearSymbolData();
        this.createDialogOpened = false;
        this.rootStore.alertStore.setDefaultPlacement();
    }

    setSymbolSearchPhrase(phrase: string): void {
        this.symbolSearchPhrase = phrase;
    }

    clearSymbolData(): void {
        this.symbols = [];
        this.currentSymbol = null;
        this.symbolSearchPhrase = '';
    }

    setCurrentSymbolAsSymbol(): void {
        if (this.currentSymbol) {
            this.symbols = [this.currentSymbol];
        }
    }

    async triggerSearch(): Promise<void> {
        this.loadingSearch = true;
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
            await this.fetchSymbols();
            runInAction(() => {
                this.loadingSearch = false;
            });
        }, 500);
    }

    async fetchSymbols(): Promise<void> {
        if (this.symbolSearchPhrase === this.currentSymbol?.name) {
            this.setCurrentSymbolAsSymbol();
            return;
        }

        this.startLoading();
        try {
            if (this.abortController) {
                this.abortController.abort();
            }

            this.abortController = new AbortController();
            const { signal } = this.abortController;

            const symbols = await this.rootStore.apiClient.symbolController.symbolControllerGetList(
                {
                    phrase: this.symbolSearchPhrase,
                    limit: 6,
                },
                { signal },
            );

            runInAction(() => {
                const newSymbols = SymbolMobxDto.createFromArray(symbols);

                if (this.currentSymbol && !newSymbols.find((s) => s.id === this.currentSymbol?.id)) {
                    this.symbols = [this.currentSymbol, ...newSymbols];
                } else {
                    this.symbols = newSymbols;
                }
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async getSymbolHistoryBySymbolIdAndDate(symbolId: string, date: string): Promise<{ close: number } | null> {
        this.startLoading();
        try {
            return this.rootStore.apiClient.symbolController.symbolControllerGetSymbolHistoryByIdAndDate({
                symbolId,
                date,
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            return null;
        } finally {
            this.stopLoading();
        }
    }
}
