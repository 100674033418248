import { makeAutoObservable } from 'mobx';
import { StornoReasonEnum } from '@app/common';
import type { RootStore } from './root-store';

export class OffboardingStore {
    rootStore: RootStore;

    opened: boolean = false;

    loading = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    open(): void {
        this.opened = true;
        this.rootStore.alertStore.setModalPlacement();
    }

    close(): void {
        this.opened = false;
        this.rootStore.alertStore.setDefaultPlacement();
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async updateStornoReason(stornoReason: StornoReasonEnum | null, stornoReasonDescription: string): Promise<void> {
        try {
            this.startLoading();

            const res = await this.rootStore.apiClient.userController.userControllerUpdateStornoReason({
                updateStornoReasonDto: {
                    stornoReason: stornoReason || undefined,
                    stornoReasonDescription,
                },
            });
            if (!res) {
                throw new Error();
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
