import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
import type { SymbolsStore } from './symbols-store';
import { SymbolPriceAlertMobxDto } from '../mobx/dtos/symbol/symbol-price-alert-mobx-dto';
import { CreateSymbolPriceAlertMobxDto } from '../mobx/dtos/symbol/create-symbol-price-alert-dto';

export class SymbolPriceAlertDialogStore {
    rootStore: RootStore;

    symbolStore: SymbolsStore;

    symbolId: string | null = null;

    symbolPriceAlert: SymbolPriceAlertMobxDto | null = null;

    createAlertDialogOpened = false;

    loading: boolean = false;

    constructor(symbolStore: SymbolsStore) {
        this.rootStore = symbolStore.rootStore;
        this.symbolStore = symbolStore;

        makeAutoObservable(this, {
            rootStore: false,
            symbolStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    openCreateAlertDialog(): void {
        this.createAlertDialogOpened = true;
        this.rootStore.alertStore.setModalPlacement();
    }

    closeCreateAlertDialog(): void {
        this.createAlertDialogOpened = false;
        this.rootStore.alertStore.setDefaultPlacement();
    }

    setSymbolId(symbolId: string): void {
        this.symbolId = symbolId;
    }

    async fetchSymbolPriceAlert(): Promise<void> {
        this.startLoading();
        try {
            if (!this.symbolId) {
                return;
            }

            let symbolPriceAlert: SymbolPriceAlertMobxDto;

            try {
                symbolPriceAlert =
                    await this.symbolStore.rootStore.apiClient.symbolPriceAlertController.symbolPriceAlertControllerGetBySymbolId(
                        { symbolId: this.symbolId },
                    );
            } catch {
                runInAction(() => {
                    this.symbolPriceAlert = null;
                });
                return;
            }

            runInAction(() => {
                this.symbolPriceAlert = SymbolPriceAlertMobxDto.create(symbolPriceAlert);
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async delete(): Promise<void> {
        this.startLoading();
        try {
            if (!this.symbolPriceAlert) {
                return;
            }

            const res =
                await this.symbolStore.rootStore.apiClient.symbolPriceAlertController.symbolPriceAlertControllerDelete({
                    id: this.symbolPriceAlert.id,
                });

            if (res) {
                runInAction(() => {
                    this.symbolPriceAlert = null;
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async create(data: CreateSymbolPriceAlertMobxDto): Promise<void> {
        this.startLoading();
        try {
            const symbolPriceAlert =
                await this.symbolStore.rootStore.apiClient.symbolPriceAlertController.symbolPriceAlertControllerCreate({
                    createSymbolPriceAlertDto: data,
                });

            if (!symbolPriceAlert) {
                throw new Error('Failed to create symbol price alert');
            }

            await this.fetchSymbolPriceAlert();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    reset(): void {
        this.symbolPriceAlert = null;
        this.symbolId = null;
        this.stopLoading();
    }
}
