import { makeAutoObservable } from 'mobx';
import { ErrorStatusEnum, StatusMessageEnum } from '@app/common';
import i18n from 'i18next';
import type { RootStore } from './root-store';
import { isEnumValue } from '../../utils/typeguards/is-enum-value';
import { AlertPlacementEnum } from '../enums/alert-placement-enum';

export class AlertStore {
    rootStore: RootStore;

    errorMessage = '';

    successMessage = '';

    warningMessage = '';

    loading = false;

    alertPlacement: AlertPlacementEnum = AlertPlacementEnum.Default;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    setErrorMessageByStatus(errorStatus: ErrorStatusEnum): void {
        if (i18n.t(`errors.${errorStatus}`) && i18n.t(`errors.${errorStatus}`) !== `errors.${errorStatus}`) {
            this.errorMessage = i18n.t(`errors.${errorStatus}`);
        } else {
            this.errorMessage = i18n.t(`errors.${ErrorStatusEnum.Default}`);
        }
    }

    setErrorMessage(errorMessage: string): void {
        this.errorMessage = errorMessage;
    }

    setSuccessMessage(successMessage: string): void {
        this.successMessage = successMessage;
        setTimeout(() => {
            this.setSuccessMessage('');
        }, 8000);
    }

    setWarningMessage(warningMessage: string): void {
        this.warningMessage = warningMessage;
    }

    setLoading(isActive: boolean): void {
        this.loading = isActive;
    }

    clearAllMessages(): void {
        this.errorMessage = '';
        this.successMessage = '';
        this.warningMessage = '';
        this.loading = false;
    }

    get translationKey(): string | null {
        if (isEnumValue(StatusMessageEnum, this.errorMessage)) {
            return Object.keys(StatusMessageEnum)[Object.values(StatusMessageEnum).indexOf(this.errorMessage)];
        }
        return null;
    }

    setDefaultPlacement(): void {
        this.alertPlacement = AlertPlacementEnum.Default;
    }

    setModalPlacement(): void {
        this.alertPlacement = AlertPlacementEnum.Modal;
    }
}
